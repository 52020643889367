* {
    margin: 0;
    padding: 0;
}

body {
    overflow-x: hidden;
}

main {
    flex: 1;
}
